<template>
	<div class="theme-dashboard-view">
		<div class="d-flex justify-content-between">
			<h1>{{ $t("category_home_title") }}</h1>

			<!-- Create button -->
			<div style="height: 65px; width: 130px">
				<button class="theme-button theme-background-light text-dark" @click="$router.push('/category/create')">
					{{ $t("category_home_create_button") }}
				</button>
			</div>
		</div>

		<!-- Loading Indictaor -->
		<div class="d-flex justify-content-center">
			<LoadingIndicator :active="loading" />
		</div>
		<!-- Loading Error Message -->
		<div v-show="load_error">
			<div class="justify-content-center text-center text-danger my-4">
				<h4>{{ load_error }}</h4>
			</div>
		</div>

		<!-- Delete Confirm -->
		<ConfirmationDialog ref="ConfirmDeleteCategory" @confirm="deleteCategory(deletingID)"
			:message="`Are you sure you want to delete ${deletingName}`" />

		<!-- Display Categories -->
		<div class="d-flex flex-wrap justify-content-around">
			<div v-for="(category, index) in category_data" :key="index" style="width: 355px">
				<div class="p-4 pb-0 m-3 rounded shadow-sm d-flex flex-column">
					<!-- Image -->
					<div class="d-flex justify-content-center h-100">
						<div>
							<img :src="category.img ?? require('@/assets/images/WhiteDebol.svg')"
								style="max-width: 100%; max-height: 400px; min-height: 150px" />
						</div>
					</div>
					<!-- Category Name -->
					<div class="p-4 text-center">
						<span class="h4" style="font-weight: 600">{{ category.name }}</span>
						<br />
						<span style="font-style: italic">{{ category.description }} </span>
					</div>
					<!-- <div>Tags:</div> -->
					<!-- <div class="d-flex flex-wrap justify-content-center">
					<span
						v-for="(tag, index) in category.tags"
						:key="index"
						class="border m-1 p-2"
					>
						{{ tag }}
					</span>
				</div> -->
					<!-- Buttons -->
					<span class="d-flex p-4 my-2 justify-content-around">
						<button class="btn btn-primary w-25" @click="updateCategory(category.id)">
							<i class="fas fa-edit"></i>
						</button>

						<!-- Delete Category -->
						<button class="btn btn-danger w-25" @click="confirmDelete(index)">
							<i class="fa fa-trash"></i>
						</button>
						<!-- ${$t('order_home_confirm_change_status_first')} -->
						<!-- deleteCategory(category.id) -->
					</span>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center" v-if="category_data.length != 0">
			<PageNav :pages="pages" :current_page="current_page" @click-page="getCategories" @next-page="nextPage"
				@prev-page="prevPage" @goto-first="gotoFirstPage" @goto-last="gotoLastPage" />
		</div>
	</div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

// Services
import CategoryService from "@/services/category.js";

export default {
	name: "CategoryHome",
	components: {
		LoadingIndicator,
		PageNav,
		ConfirmationDialog,
	},
	data() {
		return {
			loading: false,
			load_error: "",
			category_data: [],
			current_page: 1,
			pages: 0,

			deletingName: "",
			deletingID: 0,
		};
	},
	methods: {
		// Confirmation
		confirmDelete(index) {
			console.log(index);
			this.deletingName = this.category_data[index].name;
			this.deletingID = this.category_data[index].id;
			this.$refs.ConfirmDeleteCategory.showConfirmDialog();
		},

		// Page Navigation
		nextPage() {
			if (this.current_page !== this.pages) {
				this.getCategories(this.current_page + 1);
			}
		},
		prevPage() {
			if (this.current_page !== 1) {
				this.getCategories(this.current_page - 1);
			}
		},
		gotoFirstPage() {
			if (this.current_page !== 1) {
				this.getCategories(1);
			}
		},
		gotoLastPage() {
			if (this.current_page !== this.pages) {
				this.getCategories(this.pages);
			}
		},

		// Service Calls
		deleteCategory(id) {
			this.loading = true;
			this.load_error = "";

			CategoryService.deleteCategory(id).then(
				(response) => {
					this.loading = false;
					this.getCategories(this.current_page);
				},
				(error) => {
					this.loading = false;
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		updateCategory(id) {
			this.$router.push("/category/update/" + id);
		},
		getCategories(page) {
			this.loading = true;
			this.load_error = "";
			this.category_data = [];

			CategoryService.getCategories(page).then(
				(response) => {
					this.loading = false;
					console.log(response);
					this.current_page = page;
					this.category_data = response.data.data.categories;
					this.pages = response.data.data.total_pages;
				},
				(error) => {
					this.loading = false;
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
	},
	mounted() {
		this.getCategories(1);
	},
};
</script>
